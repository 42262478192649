(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);throw new Error("Cannot find module '"+o+"'")}var f=n[o]={exports:{}};t[o][0].call(f.exports,function(e){var n=t[o][1][e];return s(n?n:e)},f,f.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
window.onload = function () {
    new Vue({
        el: '#casesSection',
        data: {
            window: {
                width: window.innerWidth,
                height: window.innerHeight
            },
            haveMore: true,
            loaded: true
        },
        props: {
            category: {
                type: Number,
                default: 1
            },
            tag: {
                type: Number,
                default: 27
            },
            page: {
                type: Number,
                default: 1
            },
            pages: {
                type: Number,
                default: 0
            },
            items: {
                type: Array,
                default: () => ([])
            },
            divider: {
                type: Number,
                default: 3
            },
            pagesItems: {
                type: Number,
                default: 1
            },
            tags: {
                type: Array,
                default: () => ([])
            },
            categories: {
                type: Array,
                default: () => ([])
            }
        },
        created: function () {
            this.category = 1;
            if (window.location.pathname === '/solutions')
                this.tag = '19';
            else if (window.location.pathname === '/services/aws-devops-migration' || window.location.pathname === '/services/aws-devops')
                this.tag = '21';
            else if (window.location.href.toLowerCase().indexOf('vmware') !== -1 && window.location.href.indexOf('services/') !== -1)
                this.tag = '62';
            else if (window.location.pathname === '/')
                this.tag = '17';
            else
                this.tag = '27';

            this.url_page = window.location.href;


            this.loadTabs(this.category);

            this.categories = [
                {
                    id: 2,
                    name: 'Industries'
                },
                {
                    id: 1,
                    name: 'Technologies'
                }
            ];

            // this.handleResize();
            // if (window.location.href.indexOf('#') != -1) {
            //     this.parseDefaultData();
            // }


            this.reload();


            // window.addEventListener('resize', this.handleResize);
            // window.addEventListener('orientationchange', this.handleOrientation)
        },
        mounted: function () {
            this.enableWatchers();
        },
        destroyed: function () {
            // window.removeEventListener('resize', this.handleResize);
            // window.removeEventListener('orientationchange', this.handleOrientation)
        },
        methods: {
            enableWatchers: function () {
                this.$watch('category', function () {
                    this.tag = 0;
                    Vue.set(this, 'items', []);
                    this.reload();
                });
                this.$watch('tag', function () {
                    Vue.set(this, 'items', []);
                    this.reload();
                });
            },
            // handleResize: function () {
            //     this.window.width = window.innerWidth;
            //     if (this.window.width < 992) {
            //         this.divider = 8;
            //     }
            // },
            loadTabs: function(category_id) {
                // get tags from API
                let url = '/portfolio/api/tags?category=' + category_id;
                axios.get(url)
                    .then((resp)=> {
                        this.tags = resp.data;
                    });
            },

            scrollTop: function () {
                jQuery('html, body').animate({
                    scrollTop: jQuery(".s-portfolio").offset().top
                }, 300);
            },
            reload: function () {
                var params = {
                    category: this.category,
                    tag: this.tag,
                    tag_id: 21,
                    start: this.items.length,
                    divider: this.divider,
                    url_page: this.url_page
                };
                // this.setNewUri(params);
                this.loadItems(params, true);
            },
            parseDefaultData: function () {
                var anchor = window.location.href.split('#')[1];
                var page = 1;
                if (anchor.match(/-\d+/) !== null) {
                    page = + anchor.split('-')[anchor.split('-').length - 1];
                }

                if (page < 1) page = 1;

                anchor = anchor.replace(/-\d+/g, "");
                var cat = 0;
                for (var i in this.categories) {
                    var item = this.categories[i];
                    if (item.name === anchor) {
                        cat = + item.id;
                    }
                }
                var tag = 0;
                for (var i in this.tags) {
                    var item = this.tags[i];
                    if (item.name === anchor) {
                        tag = + item.id;
                        cat = + item.category_id;
                    }
                }
                if (tag > 0) {
                    this.tag = tag;
                }
                if (cat > 0) {
                    this.category = cat;
                }
            },
            setNewUri: function (params) {
                window.location.hash = '#' + this.getCategoryById(params.category).name;

                var paramName = 'tag';
                var paramValue = params.tag;

                if (window.location.href.indexOf('?') > 0) {
                    var url = window.location.href;
                    var refresh;
                    var pattern = new RegExp('\\b('+ paramName +'=).*?(&|#|$)');
                    if (url.search(pattern)>=0) {
                        var refresh = url.replace(pattern,'$1' + paramValue + '$2');
                        window.history.pushState({ path: refresh }, '', refresh);
                        return;
                    }
                    url = url.replace(/[?#]$/,'');
                    var refresh = url + (url.indexOf('?')>0 ? '&' : '?') + paramName + '=' + paramValue;
                    window.history.pushState({ path: refresh }, '', refresh);
                } else {
                    var refresh = window.location.href + '?' + paramName + '=' + paramValue;
                    window.history.pushState({ path: refresh }, '', refresh);
                }
            },
            loadItems: function (params, isNew) {
                isNew = isNew !== 'undefined' ? isNew : false;

                var uri = '/portfolio/api/projects?';
                uri += (Object.keys(params).map(function (key) {
                    return key + "=" + encodeURIComponent(params[key]);
                })).join('&');

                var obj = this;

                axios.get(uri)
                    .then(function (resp) {
                            if (isNew) {
                                Vue.set(obj, 'items', []);
                            }

                            return resp;
                        }
                    )
                    .then(this.parseResponse)
            },
            parseResponse: function (response) {
                this.preparedItems(response.data.list);
                this.preparedCountItems(response.data.count);

                this.haveMore = this.items.length === response.data.count;
            },
            preparedItems: function (items) {
                if (typeof items !== 'object') {
                    console.log('undefined server response');
                    this.items = [];
                    return;
                }

                // for(var key in items){
                //     var item = items[key];
                //
                //     this.items.push(item);
                // }

                this.items = items;
            },
            preparedCountItems: function (count) {
                this.pages = Math.ceil(count / this.divider);
                if (this.page > this.pages) this.page = this.pages;
            },
            showMore: function() {
                this.page += 1;
                var newParams = {
                    category: this.category,
                    tag: this.tag,
                    start: this.items.length,
                    divider: this.window.width < 992 ? 6 : 5
                };

                this.loadItems(newParams, false)
            },
            loadPage: function(newPage) {
                this.page = newPage;
                var newParams = {
                    category: this.category,
                    tag: this.tag,
                    start: (newPage - 1) * 10,
                    divider: 10,
                    url_page: this.url_page
                };

                $('html, body').animate({
                    scrollTop: $('#portfolio').offset().top - 120
                }, 500);

                this.loadItems(newParams, false)
            },
            getTagById: function (id) {
                for (var i in this.tags) {
                    var item = this.tags[i];
                    if (item.id == id) {
                        return item;
                    }
                }
            },
            getCategoryById: function (id) {
                for (var i in this.categories) {
                    var item = this.categories[i];
                    if (item.id == id) {
                        return item;
                    }
                }
            }
        }
    });
}
},{}]},{},[1])